import {
    cilBell,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilPeople,
    cilSpeedometer,
    cilFork,
    cilBriefcase,
    cilCart,
    cilMoney,
    cilGroup,
    cilFolder,
    cilWarning,
    cilCog,
    cilFingerprint,
    cilCloudUpload,
    cilCommand,
    cilShieldAlt,
    cilBadge,
    cilCommentBubble,
    cilSettings,
    cilUser,
    cibApacheSpark,
    cilMoodVeryBad
} from '@coreui/icons'

import { logo } from './logo'

export const iconsSet = Object.assign({}, { logo }, {
    cilBell,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilPeople,
    cilSpeedometer,
    cilFork,
    cilBriefcase,
    cilCart,
    cilMoney,
    cilGroup,
    cilFolder,
    cilWarning,
    cilCog,
    cilFingerprint,
    cilCloudUpload,
    cilCommand,
    cilShieldAlt,
    cilBadge,
    cilCommentBubble,
    cilSettings,
    cilUser,
    cibApacheSpark,
    cilMoodVeryBad
})