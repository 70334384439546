// const firebaseConfig = {
// 	apiKey: 'AIzaSyBlbOkfMBzROQKK_XMNSgEvjdFV5dGs_Sk',
// 	authDomain: 'inkatrek-65ea7.firebaseapp.com',
// 	databaseURL: 'https://inkatrek-65ea7-default-rtdb.firebaseio.com',
// 	projectId: 'inkatrek-65ea7',
// 	storageBucket: 'inkatrek-65ea7.appspot.com',
// 	messagingSenderId: '441698279779',
// 	appId: '1:441698279779:web:f1eaf331035801b382b356',
// 	measurementId: 'G-R4Y2KYPHEG',
// };
// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// const DB = firebase.firestore();
// export default DB;
// const dbColaborador = db.collection('colaboradores');
import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBlbOkfMBzROQKK_XMNSgEvjdFV5dGs_Sk",
    authDomain: "inkatrek-65ea7.firebaseapp.com",
    databaseURL: "https://inkatrek-65ea7-default-rtdb.firebaseio.com",
    projectId: "inkatrek-65ea7",
    storageBucket: "inkatrek-65ea7.appspot.com",
    messagingSenderId: "441698279779",
    appId: "1:441698279779:web:f1eaf331035801b382b356",
    measurementId: "G-R4Y2KYPHEG"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().settings({ timestampsInSnapshots: true });

export default firebaseApp.firestore();