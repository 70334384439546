import Vue from 'vue';
import Router from 'vue-router';
import store from '../store'
import firebase from 'firebase';
// Layouts
const ContentMasterLayout = () =>
    import ('@/Layout/Master/ContentLayout')
const ContentAdminLayout = () =>
    import ('@/Layout/Administrador/ContentLayout')

//middlewates
Vue.use(Router)



const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
})



router.beforeEach((to, from, next) => {
    // if (!to.fullPath.includes("login") && to.name != '404') {
    
    if (to.matched.some((ruta) => ruta.meta.requiresAuth)) {

        // const user = firebase.auth().currentUser;
        // if (user) {
        //     const userCollection = firebase.firestore().collection('users');
        //     userCollection
        //         .where("uidAuth", "==", user.uid)
        //         .where("estado", "==", 2)
        //         .get()
        //         .then((querySnapshot) => {
        //             if (querySnapshot.docs.length > 0) {
        //                 querySnapshot.forEach((doc) => {
        //                     store.commit('SET_USER_DATA', doc.data());
        //                     if (to.meta.isTipo == store.state.user.rol) {
        //                         next();
        //                     } else {
        //                         next({ name: 'Pagina404' });
        //                     }
        //                 });
        //             } else {
        //                 if(JSON.parse(localStorage.settings).path){
        //                     next({
        //                         name: 'Login Cliente',
        //                         params: {
        //                             rsCliente: JSON.parse(localStorage.settings).path
        //                         }
        //                     });
        //                 }else{
        //                     next({
        //                         name: 'Login',
        //                     });
        //                 }
        //             }
        //         })
        //         .catch((err) => {
        //             if(JSON.parse(localStorage.settings).path){
        //                 next({
        //                     name: 'Login Cliente',
        //                     params: {
        //                         rsCliente: JSON.parse(localStorage.settings).path
        //                     }
        //                 });
        //             }else{
        //                 next({
        //                     name: 'Login',
        //                 });
        //             }
        //         });
        // } else {
        //     if(JSON.parse(localStorage.settings).path){
        //         next({
        //             name: 'Login Cliente',
        //             params: {
        //                 rsCliente: JSON.parse(localStorage.settings).path
        //             }
        //         });
        //     }else{
        //         next({
        //             name: 'Login',
        //         });
        //     }
        // }

        if(store.state.user.usernrodoc != null && store.state.user.passnrodoc != null || localStorage.usernrodoc != null && localStorage.passnrodoc != null){
        
            const userCollection = firebase.firestore().collection('users');
            userCollection
            .where("usernrodoc", "==", store.state.user.usernrodoc == null ? localStorage.usernrodoc : store.state.user.usernrodoc)
            .where("passnrodoc", "==", store.state.user.passnrodoc == null ? localStorage.passnrodoc : store.state.user.passnrodoc)
            .where("rol", "==", to.meta.isTipo)
            .where("estado", "==", 2)
            .get()
            .then((querySnapshot) => {
                const listLogin = [];
                if(querySnapshot.docs.length > 0){
                    querySnapshot.forEach((doc) => {
                        listLogin.push({
                            id: doc.id,
                            apellidoMaterno: doc.data().apellidoMaterno,
                            apellidoPaterno: doc.data().apellidoPaterno,
                            email: doc.data().email,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                            fechaNacimiento: doc.data().fechaNacimiento,
                            idCliente: doc.data().idCliente,
                            idTipoDocumento: doc.data().idTipoDocumento,
                            nombre: doc.data().nombre,
                            passnrodoc: doc.data().passnrodoc,
                            rol: doc.data().rol,
                            telefono: doc.data().telefono,
                            usernrodoc: doc.data().usernrodoc,
                        });
                        // store.commit('SET_USER_DATA', doc.data());
                        store.commit('SET_USER_DATA',listLogin[0]);
                        localStorage.usernrodoc = store.state.user.usernrodoc;
                        localStorage.passnrodoc = store.state.user.passnrodoc;
                        // store.commit('SET_MODULES_DATA', listLogin[0]);
                        if (to.meta.isTipo == store.state.user.rol) {
                            next();
                        } else {
                            next({ name: 'Pagina404' });
                        }
                    });
                }else{
                    if(JSON.parse(localStorage.settings).path){
                        next({
                            name: 'Login Cliente',
                            params: {
                                rsCliente: JSON.parse(localStorage.settings).path
                            }
                        });
                    }else{
                        next({
                            name: 'Login',
                        });
                    }
                }
            })
            .catch((err) => {
                if(JSON.parse(localStorage.settings).path){
                    
                    next({
                        name: 'Login Cliente',
                        params: {
                            rsCliente: JSON.parse(localStorage.settings).path
                        }
                    });
                }else{
                    
                    next({
                        name: 'Login',
                    });
                }
            });
        }else{
            console.log('xDD2');

            if(JSON.parse(localStorage.settings).path){
                
                next({
                    name: 'Login Cliente',
                    params: {
                        rsCliente: JSON.parse(localStorage.settings).path
                    }
                });
            }else{
                
                next({
                    name: 'Login',
                });
            }
            
        }
        
    } else {

        next();
        localStorage.clear();
        
    }
    // }
});


function configRoutes() {
    return [{
            path: '/',
            redirect: '/master/panel-principal',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                    path: '/',
                    name: 'router page',
                    component: () =>
                        import ('@/views/Paginas/RouterPage.vue')
                },
                {
                    path: 'master',
                    redirect: '/master/panel-principal',
                    meta: { isTipo: "master", label: "Inicio" },
                    component: ContentMasterLayout,
                    children: [{
                            path: 'panel-principal',
                            name: 'Panel Principal master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: "Panel Principal"
                            },
                            component: () =>
                                import ('@/views/Master/PanelPrincipal.vue'),
                        },
                        {
                            path: 'perfil',
                            name: 'Perfil master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Mi Perfil'
                            },
                            component: () =>
                                import ('@/views/Paginas/PerfilMaster.vue')
                        },
                        {
                            path: 'posibles-clientes',
                            name: 'Posibles Clientes master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Posibles clientes'
                            },
                            component: () =>
                                import ('@/views/Master/PosiblesClientes.vue')
                        },

                        {
                            path: 'clientes',
                            name: 'Clientes master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Clientes'
                            },
                            component: () =>
                                import ('@/views/Master/Clientes.vue')
                        },
                        {
                            path: 'gestion-cliente/:id',
                            name: 'Gestion del Cliente master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Gestión del cliente'
                            },
                            component: () =>
                                import ('@/views/Master/GestionCliente.vue')
                        }, {
                            path: 'documentos-comerciales',
                            name: 'Documentos comerciales master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Documentos comerciales'
                            },
                            component: () =>
                                import ('@/views/Master/DocumentosComerciales.vue')
                        },
                        {
                            path: 'archivos/:id/:nombre',
                            name: 'archivos master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Archivos de la carpeta'
                            },
                            component: () =>
                                import ('@/views/Master/VerDocumentos.vue')
                        },
                        {
                            path: 'colaboradores',
                            name: 'Colaboradores master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Colaboradores'
                            },
                            component: () =>
                                import ('@/views/Master/Colaboradores.vue')
                        },
                        {
                            path: 'ofertas',
                            name: 'Ofertas master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Ofertas'
                            },
                            component: () =>
                                import ('@/views/Master/Ofertas.vue')
                        }, {
                            path: 'gestion-oferta/:id?',
                            name: 'Gestión de oferta master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Gestión oferta'
                            },
                            component: () =>
                                import ('@/views/Master/GestionOferta.vue')
                        },
                        {
                            path: 'ventas',
                            name: 'Ventas master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Ventas'
                            },
                            component: () =>
                                import ('@/views/Master/Ventas.vue')
                        },
                        {
                            path: 'gestion-venta/:id?',
                            name: 'Gestión de venta master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Gestión venta'
                            },
                            component: () =>
                                import ('@/views/Master/GestionVenta.vue')
                        },
                        {
                            path: 'servicios',
                            name: 'Servicios master',
                            meta: {
                                requiresAuth: true,
                                isTipo: "master",
                                label: 'Servicios'
                            },
                            component: () =>
                                import ('@/views/Master/Servicios.vue')
                        },

                    ]
                }
            ]
        },
        {
            path: '',
            redirect: '/panel-de-control',
            meta: { isTipo: "administrador", requiresAuth: true, label: "Inicio" },
            component: ContentAdminLayout,
            children: [
                
                {
                    path: 'panel-de-control',
                    name: 'Panel de Control',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Panel de control", },
                    component: () =>
                        import ('@/views/Administrador/PanelControl.vue'),
                },
                // {
                //     path: 'proyectos',
                //     name: 'Proyectos',
                //     meta: { isTipo: "guia", requiresAuth: true, label: "Proyectos" },
                //     component: () =>
                //         import ('@/views/Administrador/Proyectos/Proyectos.vue'),
                // },
                // {
                //     path: 'guias',
                //     name: 'Guías',
                //     meta: { isTipo: "guia", requiresAuth: true, label: "Guías" },
                //     component: () =>
                //         import ('@/views/Administrador/Proyectos/Guias.vue'),
                // },
                {
                    path: 'usuarios',
                    name: 'Usuarios',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Usuarios" },
                    component: () =>
                        import ('@/views/Administrador/Guias/Guias.vue'),
                },
                {
                    path: 'calificaciones',
                    name: 'Calificaciones',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Calificaciones" },
                    component: () =>
                        import ('@/views/Administrador/Calificacion/Calificacion.vue'),
                },
                {
                    path: 'comentarios',
                    name: 'Comentarios',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Comentarios" },
                    component: () =>
                        import ('@/views/Administrador/Comentarios/Comentarios.vue'),
                },
                {
                    path: 'quejas-usuario',
                    name: 'Quejas de Usuario',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Quejas de Usuario" },
                    component: () =>
                        import ('@/views/Administrador/QuejasUsuario/QuejasUsuario.vue'),
                },
                {
                    path: 'cargar-informacion',
                    redirect: '/cargar-informacion/itinerarios',
                    name: 'Cargar información',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Cargar información" },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                        path: 'itinerarios',
                        name: 'Itinerarios',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Itinerarios" },
                        // component: () => import ('@/views/Administrador/CargarInformacion/Itinerarios.vue'),
                        component: () => import ('@/views/Administrador/CargarInformacion/Itinerario/Itinerarios.vue'),
                    },{
                        path: 'gestion-itinerarios/:id?',
                        name: 'Gestión de itinerarios',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Gestión de itinerarios" },
                        // component: () => import ('@/views/Administrador/CargarInformacion/GestionItinerarios.vue'),
                        component: () => import ('@/views/Administrador/CargarInformacion/Itinerario/GestionItinerarios.vue'),
                    },{
                        path: 'lista-pasajeros',
                        name: 'Lista de Pasajeros',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Lista de Pasajeros" },
                        component: () =>
                            import ('@/views/Administrador/CargarInformacion/ListPax/ListPax.vue'),
                    },{
                        path: 'reporte',
                        name: 'Reporte',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Reporte" },
                        component: () =>
                            import ('@/views/Administrador/CargarInformacion/Reporte/Reporte.vue'),
                    },]
                },
                {
                    path: 'calidad',
                    // redirect: '/calidad/trip-evaluation-form',
                    redirect: '/calidad/equipos',
                    name: 'Calidad',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Calidad" },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                    {
                        path: 'trip-evaluation-form',
                        name: 'Trip evaluation form',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Trip evaluation form" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/TripEvaluationForm.vue'),
                    },
                    {
                        path: 'quejas-y-reclamos',
                        name: 'Quejas y reclamos',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Quejas y reclamos" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/QuejasReclamos.vue'),
                    },
                    {
                        path: 'guias-y-personal',
                        name: 'Guías y personal',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Guías y personal" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/GuiasYPersonal.vue'),
                    },
                    {
                        path: 'almacen-equipos',
                        name: 'Almacen Equipos',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Almacen Equipos" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/Equipos/AlmacenEquipos.vue'),
                    },
                    {
                        path: 'check-list-equipos',
                        name: 'Check List Equipos',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Check List Equipos" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/Equipos/CheckListEquipos.vue'),   
                    },
                    {
                        path: 'gestionamiento-equipos/:id?',
                        name: 'Gestionamiento de Equipos',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Gestionamiento de Equipos" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/Equipos/GestionamientosEquipos.vue'),   
                    },
                    {
                        path: 'alimentos-e-insumos',
                        name: 'Alimentos e Insumis',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Alimentos e Insumos" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/AlimentosEInsumos.vue'),
                    },
                    {
                        path: 'servicio-no-conforme',
                        name: 'Servicio no conforme',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Servicio no Conforme" },
                        component: () =>
                            import ('@/views/Administrador/Calidad/ServicioNoConforme.vue'),
                    },
                    { path: 'verificacion-guias',
                     name: 'Verificación guias',
                     meta: { isTipo: "administrador", requiresAuth: true, label: "Verificación de Guias"},
                     component: () =>
                     import ('@/views/Administrador/Calidad/VerificaciónGuias.vue')


                    },
                    
                ]
                },
                {
                    path: 'seguridad',
                    // redirect: '/seguridad/higiene-e-inocuidad-de-alimentos',
                    redirect: '/seguridad/alimentos',
                    name: 'Seguridad',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Seguridad" },
                    component: {
                        render(c) { return c('router-view') }

                    },
            
                    children: [
                    {
                        path: 'alimentos',
                        name: 'Alimentos',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Alimentos" },
                        component: () =>
                            // import ('@/views/Administrador/Seguridad/HigieneInocuidadAlimentos.vue'),
                            import ('@/views/Administrador/Seguridad/Alimentos/Alimentos.vue'),
                    },
                    {
                        path: 'condiciones-de-salubridad-de-campamento',
                        name: 'Condiciones de salubridad de campamento',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Condiciones de salubridad de campamento" },
                        component: () =>
                            import ('@/views/Administrador/Seguridad/CondicionesSalubridadCampamento.vue'),
                    },
                    {
                        path: 'ficha-sintomatologia',
                        name: 'Ficha de sintomatología',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Ficha de sintomatología" },
                        component: () =>
                            import ('@/views/Administrador/Seguridad/FichaSintomatologia.vue'),
                    },
                    {
                        path: 'control-de-entrega-epps',
                        name: 'Control de entrega epps',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Control de entrega epps" },
                        component: () =>
                            import ('@/views/Administrador/Seguridad/ControlEntregaEPPS.vue'),
                    },
                    {
                        path: 'charla-informativa',
                        name: 'Charla informativa',
                        meta: { isTipo: "administrador", requiresAuth: true, label: "Charla informativa"
                        },
                        component: () => import ('@/views/Administrador/Seguridad/CharlaInformativa.vue')
                    },
                    {
                        path: 'protocolos-sitio-arqueologico',
                        name: 'Protocolos',
                        meta: { isTipo: "administrador", requiresAuth: true, label: "Protocolos de sitios arqueologicos"},
                        component: () => import ('@/views/Administrador/Seguridad/CheckListProtocolosSitioArqueologico.vue')
                    },
                    {
                        path: 'inspeccion-botiquin',
                        name: 'Botiquin',
                        meta: { isTipo: "administrador", requiresAuth: true, label: "Inspección botiquin"},
                        // component: () => import ('@/views/Administrador/Seguridad/InspeccionBotiquin.vue')
                        component: () => import ('@/views/Administrador/Seguridad/InspeccionBotiquin/InspeccionBotiquin.vue')
                    },
                    {
                        path: 'accidente-incidentes',
                        name: 'Accidente e Incidentes',
                        meta: {isTipo : "administrador", requiresAuth: true, label: "Accidente e Incidentes"},
                        // component: () => import ('@/views/Administrador/Seguridad/RegistrodeAccidentes.vue')
                        component: () => import ('@/views/Administrador/Seguridad/AccidentesIncidentes/AccidentesEIncidentes.vue')
                    },
                    {
                        path: 'gestion-accidente-incidentes/:id?',
                        name: 'Gestión Accidente e Incidentes',
                        meta: {isTipo : "administrador", requiresAuth: true, label: "Gestión Accidente e Incidentes"},
                        // component: () => import ('@/views/Administrador/Seguridad/RegistrodeAccidentes.vue')
                        component: () => import ('@/views/Administrador/Seguridad/AccidentesIncidentes/GestionAccidenteIncidente.vue')
                    },
                    {
                        path: 'registro-incidente',
                        name: 'Incidente',
                        meta: {isTipo: "administrador", requiresAuth: true, label: "Incidente"},
                        component: () => import ('@/views/Administrador/Seguridad/RegistrodeIncidentes.vue')
                    },
                    {
                        path: 'limpieza-higiene',
                        name: 'Limpieza e Higiene',
                        meta: {isTipo: "administrador", requiresAuth: true, label: "Limpieza e Higiene"},
                        // component: () => import ('@/views/Administrador/Seguridad/RegistroDeLimpiezaVehiculos.vue')
                        component: () => import ('@/views/Administrador/Seguridad/LimpiezaHigiene/LimpiezaHigiene.vue')
                    },
                    {
                        path: 'gestion-limpieza-higiene/:id?',
                        name: 'Gestión Limpieza e Higiene',
                        meta: {isTipo: "administrador", requiresAuth: true, label: "Gestión de Limpieza e Higiene"},
                        // component: () => import ('@/views/Administrador/Seguridad/RegistroDeLimpiezaVehiculos.vue')
                        component: () => import ('@/views/Administrador/Seguridad/LimpiezaHigiene/GestionLimpieza.vue')
                    },
                    {
                        path: 'seguridad-ruta',
                        name: 'Seguridad Ruta',
                        meta: {isTipo: "administrador", requiresAuth: true, label: "Ruta"},
                        component: () => import ('@/views/Administrador/Seguridad/ChecklistSeguridadSaludRuta.vue')
                    },
                    {
                        path: 'trekking',
                        name: 'Trekking',
                        meta: {isTipo: "administrador", requiresAuth: true, label: "Trekking"},
                        // component: () => import ('@/views/Administrador/Seguridad/ChecklistTrekking.vue')
                        component: () => import ('@/views/Administrador/Seguridad/Trekking/Trekking.vue')
                    },
                    {
                        path: 'epps',
                        name: 'epps',
                        meta: {isTipo: "administrador", requiresAuth: true, label: "epps"},
                        component: () => import ('@/views/Administrador/Seguridad/EntregaEPPS.vue')
                    },


                    // {
                    //     path: 'registro-de-extintor',
                    //     name: 'Registro de extintor',
                    //     meta: {  isTipo: "guia", requiresAuth: true, label: "Registro de extintor" },
                    //     component: () =>
                    //         import ('@/views/Administrador/Seguridad/RegistroDeExtinto.vue'),
                    // },
                    
                ]
                },
                {
                    path: 'configuracion',
                    redirect: '/configuracion/configuracion-informacion',
                    name: 'Configuración información',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Configuración información" },
                    component: {
                        render(c) { return c('router-view') }

                    },
                    children: [
                    {
                        path: 'configuracion-informacion',
                        name: 'Configuración información',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Configuración información" },
                        component: () =>
                            import ('@/views/Administrador/ConfiguracionInformacionApp/ConfiguracionInformacionApp.vue'),
                    },
                    {
                        path: 'gestion-configuracion-informacion',
                        name: 'Gestión de configuración información app',
                        meta: {  isTipo: "administrador", requiresAuth: true, label: "Gestion de Configuracion de Informacion" },
                        component: () =>
                            import ('@/views/Administrador/ConfiguracionInformacionApp/GestionConfiguracionInformacionApp.vue'),
                    },
                    ]
                    },
                    
           

                {
                    path: 'perfil',
                    name: 'Perfil',
                    meta: { isTipo: "administrador", requiresAuth: true, label: "Perfil" },
                    component: () =>
                        import ('@/views/Paginas/Perfil.vue'),
                },
            ]
        },
        {
            path: '/',

            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                    path: '404',
                    name: 'Pagina404',
                    component: () =>
                        import ('@/views/Paginas/404.vue')
                },
                {
                    path: '500',
                    name: 'Pagina500',
                    component: () =>
                        import ('@/views/Paginas/500.vue')
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: () =>
                        import ('@/views/Paginas/Login.vue')
                },
                {
                    path: ':rsCliente/login',
                    name: 'Login Cliente',
                    component: () =>
                        import ( /* webpackChunkName: "Login Cliente" */ '@/views/Paginas/LoginCliente.vue')
                },
                {
                    path: '*',
                    name: '404',
                    component: () =>
                        import ('@/views/Paginas/404.vue')
                },
            ]
        }
    ]
}

export default router;