import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import firebase from 'firebase'
import DB from '@/helpers/FirebaseConnection';

Vue.use(DB)

import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
// import VueTour from 'vue-tour'

import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

import VueSweetalert2 from 'vue-sweetalert2';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import UUID from "vue-uuid";

// require('vue-tour/dist/vue-tour.css')

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component('v-select', vSelect);
localize("es", es);

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.use(UUID);
// Vue.use(VueTour)
Vue.filter('truncate', function(value, length) {
    if (value.length < length) {
        return value;
    }

    length = length - 3;
    return value.substring(0, length) + '...';
});

let app = null

firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            store,
            icons,
            render: h => h(App)
        }).$mount('#app')
    }
})

// var app = new Vue({
//     el: '#app',
//     router,
//     store,
//     icons,
//     template: '<App/>',
//     components: {
//         App
//     }
// })